<template>
  <b-form-group>
    <label>
      {{ vlabel }}
      <span v-if="vmandatory" class="text-danger mr5">*</span>
    </label>
    <b-badge
      v-if="vinfo"
      variant="info"
      pill
      class="help-badge"
      v-b-tooltip.hover.right="
        vinfo
      "
    >
      <i class="fas fa-question"></i>
    </b-badge>
    <template v-if="editFormValue != editFormId">
      <span class="d-block fw_500">{{ vvalue }}</span>
    </template>
    <template v-else-if="editFormValue == editFormId">
      <b-form-textarea 
        v-model="vvalue" 
        :placeholder="vplaceholder" 
        :rows="vrows"
        :formatter="v => v.replace(/\s\s\s+/, ' ')"
        :maxlength="vmaxlength"
        :tabindex="tabIndex"
      ></b-form-textarea> 
      <VValidate :name="vname || vlabel" v-model="vvalue" :rules="vrules" />
    </template>
  </b-form-group>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    name: String,
    value: String,
    label: String,
    info: String,
    rules: [Object, String],
    placeholder: {
      type: String,
      default: ''
    },
    mandatory: {
      type: Boolean,
      default: true
    },
    rows: {
      type: String,
      default: '4'
    },
    isref: {
      type: Boolean,
      default: false
    },
    refdata: String,
    maxref: {
      type: Number,
      default: null
    },
    maxlength: String,
    tabIndex: {
      type: String,
      default: '',
    },
    editFormId: {
      type: String,
      default: '',
    },
    editFormValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      vname: this.name,
      vlabel: this.label,
      vinfo: this.info,
      vplaceholder: this.placeholder,
      vmandatory: this.mandatory,
      vrows: this.rows,
      visref: this.isref,
      vmaxref: this.maxref,
      vmaxlength: this.maxlength
    }
  },
  computed: {
    vvalue: {
      get() {
        return this.value
      },
      set(v) {
        if (v) {
          this.$emit('input', v)
        } else {
          this.$emit('input', v)
        }
      }
    },
    vref: {
      get() {
        return this.refdata
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    vrules: {
      get() {
        return this.rules
      }
    }
  },
  watch: {
    vref: {
      handler: _.debounce(function(v) {
        let html = v;
        let div = document.createElement("div");
        div.innerHTML = html;
        let text = div.textContent || div.innerText || "";
        
        if (!this.vmaxref) {
          this.vvalue = text;
        } else {
          this.vvalue = _.truncate(text, {
            length: this.vmaxref, // maximum 160 characters
            separator: /,?\.* +/ // separate by spaces, including preceding commas and periods
          }).replace('...', '');
        }
      }, 1000)
    }
  }
}
</script>